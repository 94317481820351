<template>
    <div
        id="messages"
        class="orders-details-tabs__messages tab-content"
    >
        <div class="chat">
            <div
                ref="chat_wrapper"
                class="chat-wrapper"
            >
                <NoResult
                    v-if="messages.length === 0"
                    type="writers-messages"
                />
                <div>
                    <div
                        v-for="(message, message_index) in messages"
                        :key="message_index"
                        ref="chat_item"
                        class="chat_item"
                        :class="{ 'chat_item__support': !!message.author && message.author.type !== 'Writer' }"
                    >
                        <div
                            v-if="!!message.author && message.author.type !== 'Writer'"
                            class="chat_item__avatar"
                        >
                            <avatar
                                :avatar-url="message.author.avatar_url"
                                :nickname="message.author.firstname"
                                :user="message.author.type"
                            />
                        </div>
                        <div class="chat_item__inside">
                            <div
                                v-if="!!message.author && message.author.type !== 'Writer'"
                                class="chat_item__content-header"
                            >
                                <span
                                    class="chat_item__content-header__name"
                                >
                                    <span>
                                        {{ message.author.firstname }} ({{ message.author.type }})
                                    </span>
                                </span>
                            </div>
                            <div
                                class="chat_item__content"
                                :class="{ 'revision' : message.type === 'revision'}"
                            >
                                <div
                                    v-if="message.type === 'revision'"
                                    class="chat_item__content-revision"
                                >
                                    Revision request
                                </div>
                                <div
                                    class="chat_item__content-text"
                                    :class="{ 'revision-text' : message.type === 'revision'}"
                                    v-html="splitByCharacters(message.details)"
                                />
                                <span
                                    v-if="message.created_at"
                                    class="chat_item__content-header__time"
                                >
                                    {{ message.created_at | moment_from }} ago <br>
                                    <small>
                                        {{ message.created_at | moment_short_text }}
                                    </small>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <MessageForm
                :is-loading="isLoading"
                :clear-form="clearForm"
                @typing="typingListener"
                @sendMessage="sendMessage"
                @formCleared="clearForm = false"
            >
                <template #typing>
                    <span>{{ typingMessage }}</span>
                    <div
                        v-if="typingMessage"
                        class="dot-flashing"
                    />
                </template>
            </MessageForm>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus';
import Api from '@/helpers/api/index.js';

// Mixins
import filtersMixin from '@/mixins/filtersMixin.js';

// Components
import NoResult from '@/components/common/NoResult';
import Avatar from '@/components/common/Avatar';
import MessageForm from '@/components/common/MessageForm';

export default {
    components: {
        NoResult,
        Avatar,
        MessageForm
    },
    mixins: [filtersMixin],
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        messages: [],
        isLoading: false,
        clearForm: false,
        typing: false,
        typingMessage: null
    }),
    computed: {
        ...mapGetters('writer', [
            'getterMainWriterInfo'
        ]),
        orderid() {
            return this.order.orderid;
        }
    },
    watch: {
        typing(newVal) {
            this.$socket.emit('order:typing', {
                typing: newVal,
                orderid: this.orderid
            })
        }
    },
    async created() {
        this.messages = [...this.order.messages]
        this.chatWSConnect()
        await this.markAsRead();
    },
    async mounted() {
        this.scrollToEndPage()
    },
    methods: {
        async markAsRead() {
            try {
                await Api.put('/api/order-message/mark-as-read', {
                    orderid: this.orderid
                })
                this.$emit('markAsRead');
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            }
        },
        async sendMessage({ message }) {
            try {
                this.isLoading = true;
                const { data } = await Api.post('/api/order-message/create-message', {
                    orderid: this.orderid,
                    message
                })
                eventBus.$emit('showSnackBar', 'Your message has been received', 'success');

                this.$emit('actionAddMessage', data);
                this.$nextTick(() => { this.scrollToEndPage() })
                this.clearForm = true
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.$v.$reset();
                this.isLoading = false;
            }
        },
        scrollToEndPage() {
            const elem = this.$refs.chat_wrapper
            elem.scrollTo({ top: 99999999, behavior: 'smooth' })
        },
        splitByCharacters(text) {
            const splitString = text.split('\n').join('<br>')
            return splitString
        },
        typingListener(value) {
            this.typing = value
        },
        chatWSConnect() {
            this.$socket.on('order:newMessage', (data) => {
                if (data.author.id === this.getterMainWriterInfo.id) return
                this.messages = [...this.messages, data]
                this.$nextTick(() => {
                    this.scrollToEndPage()
                })
            })
            this.$socket.on('order:typing', (data) => {
                if (data.role === 'Writer' || data.role === 'Lead') return
                this.typingMessage = data.message
            })
        }
    }
};
</script>

<style lang="scss">
    .orders-details-tabs__messages{
        .chat{
            padding: 0 25px;
            padding-bottom: 30px;
            @media all and (max-width: 576px){
                padding: 0 15px;
            }
            &-wrapper {
                max-height: 500px;
                overflow-y: scroll;
                margin-bottom: 10px;
            }
        }

        .chat_item__content--writer {
            background: #f0f0f0;
        }
    }
</style>
